import { container, title } from 'assets/jss/material-kit-react';

const blogPageStyle = theme => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#000',
    textDecoration: 'none',
    textShadow: '2px 0 10px #FFFFFF',
    fontFamily: 'Great Vibes',
    fontSize: '4rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    color: '#000',
    fontSize: '1.313rem',
    margin: '10px 0 0',
    display: 'inline-block',
    position: 'relative',
    textShadow: '2px 5px 5px #FFFFFF',
    fontWeight: 400,
  },
  credit: {
    position: 'absolute',
    bottom: '70px',
    left: '10px',
    zIndex: 2,
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
});

export default blogPageStyle;
