import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { Link } from 'react-scroll';
// layout
import Layout from 'components/Layout';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';

import blogPageStyle from 'assets/jss/material-kit-react/views/blogPage';

// Sections for this page
import NewsSection from 'components/BlogPage/NewsSection';

const Blog = ({ classes, data }) => {
  if (!data) return null;

  const content = data.prismicNewsPage.data;
  const { lang } = data.prismicNewsPage;
  const alternateLanguages = data.prismicNewsPage.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const articles = data.allPrismicNewsArticle.nodes;

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO title="Read our latest news | Servitours" />
      <Parallax filter image={content.image.url}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12}>
              <h1 className={classes.title}>{RichText.asText(content.headline.raw)}</h1>
              <h4 className={classes.subtitle}>{RichText.asText(content.description.raw)}</h4>
              <br />
            </GridItem>
            <GridItem xs={12}>
              <div id="scrollDownButton" className="flex-center">
                <Link
                  className="js-scroll-trigger"
                  to="news-section"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  <span className="blue-text" />
                  <span className="white-text" />
                  <span className="red-text" />
                </Link>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <NewsSection data={articles} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPageQuery($lang: String = "fr-fr") {
    prismicNewsPage(lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        description {
          raw
        }
        headline {
          raw
        }
        image {
          url
        }
      }
      lang
      type
    }
    allPrismicNewsArticle(filter: {lang: { eq: $lang }}) {
      nodes {
        alternate_languages {
          lang
          type
          uid
        }
        data {
          hero_image {
            url
            thumbnails {
              mobile {
                url
              }
            }
          }
          title {
            raw
          }
        }
        id
        lang
        type
        url
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

export default withStyles(blogPageStyle)(Blog);
