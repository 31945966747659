import { cardTitle, title } from 'assets/jss/material-kit-react';
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles';

const newsStyle = {
  section: {
    padding: '20px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
  ...imagesStyles,
  cardTitle: {
    ...cardTitle,
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardBody: {
    borderTop: 'solid 0.5px grey',
    padding: '0rem',
  },
  textMuted: {
    color: '#6c757d',
  },
};

export default newsStyle;
