import React, { Fragment } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { RichText } from 'prismic-reactjs';
import LazyLoad from 'react-lazyload';
import { isMobile } from 'react-device-detect';

import linkResolver from 'utils/linkResolver';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Spinner from 'components/Spinner/Spinner';

import newsStyle from 'assets/jss/material-kit-react/views/blogPageSections/newsStyle';

const NewsSection = ({ classes, data }) => {
  // Default title when post has no title set
  const defaultTitle = 'Untitled';
  if (data) {
    return (
      <Fragment>
        <div className={classes.section}>
          <GridContainer justifyContent="center" id="news-section">
            {data.map(document => {
              return (
                <GridItem xs={12} sm={12} md={4} key={document.id}>
                  <AniLink to={document.url} paintDrip hex="#819E84">
                    <LazyLoad height={200} once offset={[-100, 100]} placeholder={<Spinner />}>
                      <Card>
                        {isMobile && (
                          <img
                            className={classes.imgCardTop}
                            src={document.data.hero_image.thumbnails.mobile.url}
                            alt="Card-img-cap"
                          />
                        )}
                        {!isMobile && (
                          <img
                            className={classes.imgCardTop}
                            src={document.data.hero_image.url}
                            alt="Card-img-cap"
                          />
                        )}
                        <CardBody className={classes.cardBody}>
                          <h4 className={classes.cardTitle}>
                            {RichText.asText(document.data.title.raw).length !== 0
                              ? RichText.asText(document.data.title.raw)
                              : defaultTitle}
                          </h4>
                        </CardBody>
                      </Card>
                    </LazyLoad>
                  </AniLink>
                </GridItem>
              );
            })}
          </GridContainer>
        </div>
      </Fragment>
    );
  }
  return null;
};

export default withStyles(newsStyle)(NewsSection);
